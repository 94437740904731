import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ProductAvailabilityLabel from 'src/components/productAvailabilityLabel';
import RootStore from 'src/stores/RootStore';
import { TProductModel } from 'src/stores/types/ProductTypes';

interface SelectSaddleAndGripProps {
    store?: typeof RootStore.Type;
    selectedSaddleAndGripCode: string;
    setSelectedSaddleAndGripCode: (selected: string) => void;
}

const SelectSaddleAndGrip = inject('store')(
    observer(({ store, selectedSaddleAndGripCode, setSelectedSaddleAndGripCode }: SelectSaddleAndGripProps) => {
        const saddleAndGripList: TProductModel[] | [] = store?.products.saddleAndGripList;
        const [isOpen, setOpen] = useState(false);
        // This indicate if at least 1 of the parts is in stock, so we don't show user None option
        // const [inStock, setInStock] = useState(false);

        useEffect(() => {
            if (saddleAndGripList?.length) {
                const defaultValue = saddleAndGripList.find(part => part.productInformation.inStock);
                if (defaultValue) {
                    setSelectedSaddleAndGripCode(defaultValue.id);
                    // setInStock(true);
                } else {
                    setSelectedSaddleAndGripCode('empty');
                }
            }
        }, [saddleAndGripList]);

        if (!saddleAndGripList?.length) {
            return null;
        }

        const getOption = (value: string) => {
            const foundOption = saddleAndGripList.find(part => part.id === value);
            return foundOption ? (
                foundOption.productInformation.color
            ) : (
                <span>
                    <FormattedMessage id="product.saddle.and.grip.empty" />
                </span>
            );
        };

        return (
            <FormControl variant="standard" sx={{ margin: '10px 0 10px', width: '100%' }}>
                <InputLabel htmlFor="saddle-and-grip-item">
                    <FormattedMessage id="select.saddle_and_grip" />
                </InputLabel>
                <Select
                    data-testid="saddleAndGripsSelector"
                    labelId="saddle-and-grip-item"
                    open={isOpen}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={selectedSaddleAndGripCode}
                    name="selectedSaddleAndGripsColor"
                    displayEmpty={false}
                    renderValue={(value: any) => getOption(value)}
                    onChange={e => setSelectedSaddleAndGripCode(e.target.value as string)}
                    sx={{ overflow: 'hidden' }}
                >
                    {/* {!inStock && ( */}
                        <MenuItem key="empty" value="empty" data-testid="no-saddle-and-grip">
                            <FormattedMessage id="product.saddle.and.grip.empty">
                                {text => <em>{`${text}`}</em>}
                            </FormattedMessage>
                        </MenuItem>
                    {/* )} */}
                    {saddleAndGripList.map((part: TProductModel) => (
                        <MenuItem
                            key={part.id}
                            value={part.id}
                            data-testid={`saddle-and-grip-${part.id}`}
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            disabled={!part.productInformation.inStock}
                        >
                            <span>
                                <span>{part.productInformation.color}</span>
                            </span>
                            <ProductAvailabilityLabel
                                inStock={part.productInformation.inStock}
                                availableFrom={part.productInformation.availableFrom}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }),
);

export default SelectSaddleAndGrip;

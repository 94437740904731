import AddIcon from '@mui/icons-material/Add';
import { Button, FormControl, FormHelperText, Grid, InputLabel, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import InputReference from 'src/components/InputReference';
import Loading from 'src/components/loading/Loading';
import QuantitySelector from 'src/components/QuantitySelector';
import { Battery, TCartItem } from 'src/stores/types/CartItemModel';
import Store from 'src/stores/RootStore';
import { ProductModel, TProductModel } from 'src/stores/types/ProductTypes';
import AnalyticsEcommerce from 'src/utils/analytics-ecommerce';
import SelectBattery from './SelectBattery';
import SelectSaddleAndGrip from './SelectSaddleAndGrip';
import SelectStrap from './SelectStrap';

interface ProductFormProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    setCartTypeToBike: () => void;
    product: TProductModel;
}

const ProductForm = inject('store')(
    observer(({ classes, store, setCartTypeToBike, product }: ProductFormProps) => {
        const intl = useIntl();

        const [reference, setReference] = useState('');
        const [quantity, setQuantity] = useState(1);
        const [required, setRequired] = useState(false);

        const isSubmitDisabled = !product.productInformation.stockQuantity;
        const calculatedAddButtonClasses = classNames(classes.button, {
            [classes.buttonDisabled]: isSubmitDisabled,
        });

        const addProduct = () => {
            if (store!.products.selectedBikeBatteryCode.length > 0 && ProductModel.is(product)) {
                const battery: typeof Battery.CreationType = store!.products.getBatteryData(
                    product,
                    store!.products.selectedBikeBatteryCode,
                );

                if (battery.code === 'N/A') {
                    battery.description = intl.formatMessage({ id: 'product_detail.withoutBattery' });
                }

                const saddleAndGrip =
                    store?.products.selectedSaddleAndGripCode === 'empty'
                        ? ''
                        : store?.products.selectedSaddleAndGripCode;
                const strap = store?.products.selectedStrapCode === 'empty' ? '' : store?.products.selectedStrapCode;

                const newProduct = {
                    id: `${product.id}`,
                    type: product.type,
                    product,
                    battery,
                    saddleAndGrip,
                    strap,
                    quantity,
                    reference,
                } as TCartItem;

                store!.cart.addCartItem(newProduct, quantity);
                setCartTypeToBike();
                store!.products.toggleProductDetailDialog(false);

                // Sending item added into cart event to GA
                AnalyticsEcommerce.getInstance()
                    .clear()
                    .addProductToCart({
                        id: product.id,
                        name: product.name,
                        list: 'Products List',
                        brand: 'QWIC',
                        category: `${product.serie}/${product.model}`,
                        variant: product.productInformation?.color || undefined,
                        price: product.getPrice(),
                        quantity: quantity,
                        batteryCode: battery.code,
                        batteryPrice: battery.price,
                    })
                    .sendPageView();

                setQuantity(1);
                setReference('');
            } else {
                setRequired(true);
            }
        };

        if (store?.products.loadingConfigurableParts) {
            return <Loading small />;
        }

        return (
            <div className={classes.actions}>
                <div className={classes.itemAction} style={{ marginBottom: '10px' }}>
                    <div className={classes.battery}>
                        <FormControl className={classes.batteryField} variant="standard">
                            <InputLabel htmlFor="battery-item">
                                <FormattedMessage id="select.battery" />
                            </InputLabel>
                            <SelectBattery
                                selectedBatteryCode={store!.products.selectedBikeBatteryCode}
                                product={product}
                                setSelectedBattery={(batteryCode: string) => {
                                    setRequired(false);
                                    store!.products.setSelectedBikeBatteryCode(batteryCode);
                                }}
                            />
                            {required && (
                                <FormHelperText className={classes.batteryAlert}>
                                    <FormattedMessage id="alert.select_battery" />
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </div>

                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <SelectSaddleAndGrip
                            selectedSaddleAndGripCode={store?.products.selectedSaddleAndGripCode || ''}
                            setSelectedSaddleAndGripCode={store?.products.setSelectedSaddleAndGripCode}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SelectStrap
                            selectedStrapCode={store?.products.selectedStrapCode || ''}
                            setSelectedStrapCode={store?.products.setSelectedStrapCode}
                        />
                    </Grid>
                </Grid>

                <div className={classes.itemAction} style={{ flexDirection: 'row' }}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} sm={12}>
                            <InputReference reference={reference} updateReference={setReference} />
                        </Grid>
                    </Grid>
                    <div className={classes.quantity}>
                        <QuantitySelector
                            quantity={quantity}
                            maxQuantity={product.productInformation.stockQuantity}
                            updateQuantity={setQuantity}
                            isLabelBelow={true}
                        />
                    </div>
                </div>

                <Grid container spacing={3} className={classes.buttons}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            onClick={() => store!.products.toggleProductDetailDialog()}
                            variant="outlined"
                            fullWidth
                            className={classes.button}
                        >
                            <FormattedMessage id="returns.form_label_cancel" />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            data-testid="add-product-dialog"
                            disabled={isSubmitDisabled}
                            className={calculatedAddButtonClasses}
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={addProduct}
                        >
                            <FormattedMessage id="dialog.addToCart" />
                            <AddIcon />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }),
);

function styles({ spacing, palette }: Theme) {
    return createStyles({
        actions: {
            display: 'flex',
            flexDirection: 'column',
            margin: '1rem 0',
        },
        itemAction: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            marginBottom: '2rem',
        },
        quantity: {
            display: 'flex',
            flex: 1,
            marginLeft: '20px',
            marginTop: spacing(3),
        },
        battery: {
            display: 'flex',
            flex: 1,
        },
        batteryField: {
            flex: 1,
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            paddingTop: '1em',
            paddingBottom: '1em',
            paddingLeft: '2em',
            paddingRight: '2em',
            justifyContent: 'space-between',
        },
        buttonDisabled: {
            'pointer-events': 'none',
        },
        batteryAlert: {
            color: palette.secondary.main,
            position: 'absolute',
            top: '100%',
        },
    });
}

const ProductFormWithStyles = withStyles(styles)(ProductForm);

export default ProductFormWithStyles;

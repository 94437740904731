import { flow, types } from 'mobx-state-tree';
import Service from 'src/services/api/Api';
import { Battery } from 'src/types/commons/products';
import { logRejectedModel } from 'src/utils/logger';
import { BatteryModel } from './types/ProductTypes';

const BatteriesStore = types
    .model('BatteriesListStore', {
        batteries: types.optional(types.map(BatteryModel), {}),
    })
    .actions(self => ({
        loadBatteries: flow(function* (params: { country: string }) {
            try {
                const getBatteriesResponse: any = yield Service.getBatteries(params);
                if (getBatteriesResponse.status === 200) {
                    getBatteriesResponse.data.forEach((battery: Battery) => {
                        if (BatteryModel.is(battery)) {
                            self.batteries.set(battery.id, battery);
                        } else {
                            logRejectedModel(BatteryModel.name, battery);
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }),
        clearBatteries: () => {
            self.batteries.clear();
        }
    }));

export default BatteriesStore;

import React, { useState } from 'react';
import Service from 'src/services/api/Api';
import RootStore from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { AppPageRoutes } from 'src/services/api/models';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

interface ImpersonateDialogProps {
    user?: typeof UserStore.Type;
    store?: typeof RootStore.Type;
}

const isInvalid = (value: string): boolean => {
    return (value !== '' && !/^[Kk][0-9]{5}$/.test(value));
};

const ImpersonateDialog = inject(
    'user',
    'store',
)(
    observer(({ user, store }: ImpersonateDialogProps) => {
        const theme = useTheme();
        const navigate = useNavigate();

        const [inputValue, setInputValue] = useState('');

        const handleImpersonation = (accountNumber = '') => {
            if (!accountNumber) {
                setInputValue('');
            }

            user!.setImpersonate(accountNumber);
            Service.setImpersonateHeaders(accountNumber);

            store!.products.loadProducts('');

            user!.toggleImpersonateDialog();
            navigate(AppPageRoutes.PRODUCTS);
        };

        const setImpersonate = () => handleImpersonation(inputValue);
        const clearImpersonate = () => handleImpersonation();

        return (
            <Dialog
                data-testid="impersonate-dialog"
                open={user!.impersonateDialogOpen}
            >
                <DialogTitle>
                    <FormattedMessage id="impersonate.title" />
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: 0 }}>
                    <FormControl
                        sx={{
                            margin: theme.spacing(1),
                            minWidth: 250,
                            maxWidth: 300,
                            flex: 1,
                        }}
                    >
                        <InputLabel htmlFor="impersonate-dialog-input" sx={{ left: '-15px', top: '5px' }}>
                            <FormattedMessage id="impersonate.label" />
                        </InputLabel>
                        <Input
                            data-testid="impersonate-dialog-input"
                            id="component-simple"
                            sx={{ minHeight: '42px' }}
                            value={inputValue}
                            placeholder="K#####"
                            onChange={({ target }) => {
                                setInputValue(target.value.toUpperCase());
                            }}
                            autoFocus
                            onKeyUp={(e) => {
                                if (e.key === 'Enter' && !isInvalid(inputValue)) {
                                    // Small timeout to allow the dialog to close before navigating
                                    setTimeout(() => { setImpersonate() }, 10);
                                }
                            }}
                            error={isInvalid(inputValue)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', padding: '1rem 24px' }}>
                    <Button variant="outlined" fullWidth disabled={isInvalid(inputValue)} onClick={setImpersonate}>
                        <FormattedMessage id="impersonate.button" />
                    </Button>
                    <Button variant="contained" fullWidth onClick={clearImpersonate}>
                        <FormattedMessage id="impersonate.clear" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }),
);

export default ImpersonateDialog;

import { StageEnviroments, oneMB } from './constants';

const maxFileSizeMB = 1;
const maxReturnRequestAttachmentSizeMB = 50;
const maxReturnRequestFileQuantity = 10;
const maxContactAttachmentSizeMB = 4;

export const environments: string[] = [
    StageEnviroments.DEVELOPMENT,
    StageEnviroments.STAGING,
    StageEnviroments.PRODUCTION,
];

const CONFIG = {
    API: import.meta.env.VITE_APP_API || '',
    DOMAIN: import.meta.env.VITE_APP_HOST_DOMAIN || StageEnviroments.LOCAL,
    FILE_ALLOWED_FORMATS: 'image/*,.pdf,.doc,.docx',
    FILE_MAX_SIZE: maxFileSizeMB * oneMB,
    FILE_MAX_SIZE_MB: maxFileSizeMB,
    RETURN_REQUEST_MAX_FILE_QUANTITY: maxReturnRequestFileQuantity,
    RETURN_REQUEST_MAX_FILE_SIZE: maxReturnRequestAttachmentSizeMB * oneMB,
    RETURN_REQUEST_MAX_FILE_SIZE_MB: maxReturnRequestAttachmentSizeMB,
    RETURN_REQUEST_MAX_INVOICE_FILE_SIZE: maxContactAttachmentSizeMB * oneMB,
    CONTACT_MAX_FILE_SIZE: maxContactAttachmentSizeMB * oneMB,
    CONTACT_MAX_FILE_SIZE_MB: maxContactAttachmentSizeMB,
    WINDOW_WIDTH_THRESHOLD: 420,
    APP_ENVIRONMENT: import.meta.env.VITE_APP_ENVIRONMENT || StageEnviroments.LOCAL,
    SENTRY_RELEASE: import.meta.env.VITE_APP_SENTRY_RELEASE || 'test',
    SENTRY_DSN: import.meta.env.VITE_APP_SENTRY_DSN || '',
    ADD_SENTRY_RELEASE: import.meta.env.VITE_APP_ADD_SENTRY_RELEASE,
    GOOGLE_ANALYTICS_ID: import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID || '',
    HOTJAR_ID: import.meta.env.VITE_APP_HOTJAR_ID || '',
    HOTJAR_VERSION: import.meta.env.VITE_APP_HOTJAR_VERSION || '',
    CDN_NAME: 'dmsstxj50',
    AUTH0_DOMAIN: import.meta.env.VITE_APP_AUTH0_DOMAIN || '',
    AUTH0_CLIENT_ID: import.meta.env.VITE_APP_AUTH0_CLIENT_ID || '',
    AUTH0_AUDIENCE: import.meta.env.VITE_APP_AUTH0_AUDIENCE || '',
    GRIDLY_API_KEY: import.meta.env.VITE_APP_GRIDLY_READONLY_API_KEY,
    GRIDLY_VIEW_ID: '4v6n87m8ltsnv',
    IMPERSONATION_HEADER: 'X-Impersonate-Customer',

    // An hour of interval in milliseconds, change the value with a negative number for disabling auto retrieve new notifications
    ORDER_NOTIFICATION_REFRESH_INTERVAL: 60 * 60 * 1000,
};
export const distNumber = `${import.meta.env.SEED_STAGE_NAME}-${import.meta.env.SEED_BUILD_ID}` || '0';

export default CONFIG;
